/* eslint-disable camelcase */
import NewsletterBlock from 'components/contentBlocks/Newsletter';
import PropTypes from 'prop-types';
import React from 'react';
import decodeHTMLEntities from '@nwg/core-web/utils/decodeHTMLEntities';
import transformNewsletterForm from 'libs/wordpress/utils/transformNewsletterForm';
import { wysiwygProp } from 'utils/proptypes/modules/textProps';

const NewsletterTransformer = ({ content, isColumn = false }) => {
    const { heading, newsletter_form: newsletterForm, paragraph } = content;

    const formData = transformNewsletterForm(newsletterForm);

    return (
        <NewsletterBlock
            form={formData}
            fullWidth={!isColumn}
            heading={decodeHTMLEntities(heading?.text)}
            headingSize="lg"
            headingTag={heading?.type}
            text={paragraph}
        />
    );
};

NewsletterTransformer.propTypes = {
    content: PropTypes.shape({
        heading: PropTypes.object,
        newsletter_form: PropTypes.object.isRequired,
        paragraph: wysiwygProp,
    }).isRequired,
    isColumn: PropTypes.bool,
};

export default NewsletterTransformer;
