import { headingSizeProp, headingTagProp, wysiwygProp } from 'utils/proptypes/modules/textProps';

import ColumnsLayout from 'components/contentLayouts/ColumnsLayout';
import Heading from 'components/text/Heading';
import NewsletterForm from 'components/Newsletter';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import { TextLinkWysisyg } from 'components/text/TextLink';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import styled from 'libs/styled';
import { toCamelCase } from 'utils/string';

const Div = styled('div')``;

const Wrapper = styled('div', { shouldForwardProp: prop => ['fullWidth'].indexOf(prop) === -1 })`
    ${({ fullWidth }) =>
        !fullWidth &&
        `
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
    `}
`;

/**
 * Display a newsletter signup field
 *
 * @param {bool} fullWidth=true - Adjusts the appearance for full screen.
 * @param {string} heading - Heading as a string.
 * @param {string} headingTag - The html-tag used by heading; h1, h2, h3 etc.
 * @param {string} headingSize - Determines the size of the heading.
 * @param {object} form - An object that contains all form settings and data.
 * @param {array} text - wysiwyg displayes above the form.
 */

const Newsletter = ({ form, fullWidth = true, heading, headingSize, headingTag, text }) => (
    <Wrapper fullWidth={fullWidth}>
        <ColumnsLayout
            blocks={[
                <Div key="heading" size={fullWidth ? [12, null, 4, 6] : 12}>
                    {heading && (
                        <Heading as={headingTag} pb={['32px', null, '56px']} size={headingSize}>
                            {heading}
                        </Heading>
                    )}
                </Div>,
                <Div
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-end"
                    height="100%"
                    key="form"
                    size={fullWidth ? [12, null, 8, 6] : 12}
                >
                    {text && (
                        <Wysiwyg
                            data={text}
                            textComponent={props => <Paragraph size="xl" mb="32px" {...props} />}
                            tagComponents={{ url: TextLinkWysisyg }}
                        />
                    )}
                    <NewsletterForm
                        color="black"
                        id={heading ? toCamelCase(heading) : 'newsletter-content-blocks'}
                        {...form}
                    />
                </Div>,
            ]}
            gutterHeight={0}
            style={{
                justifyContent: 'flex-start',
                alignItems: 'space-between',
                height: '100%',
            }}
        />
    </Wrapper>
);

Newsletter.propTypes = {
    form: PropTypes.object,
    fullWidth: PropTypes.bool,
    heading: PropTypes.string,
    headingSize: headingSizeProp,
    headingTag: headingTagProp,
    text: wysiwygProp,
};

export default Newsletter;
