/* eslint-disable camelcase */
import AspectWrapper from 'components/wrappers/AspectWrapper';
import BackgroundSwitch from 'components/background/BackgroundSwitch';
import ColumnsLayout from 'components/contentLayouts/ColumnsLayout';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import NewsletterBlock from 'libs/wordpress/content/cmsBlocks/NewsletterBlock';
import PropTypes from 'prop-types';
import React from 'react';
import activeSetting from 'libs/wordpress/utils/activeSetting';
import { bleedMargins } from 'config/theme/contentMargins';
import { mediaQueries } from 'config/theme/breakpoints';
import moduleMargins from 'config/theme/moduleMargins';
import styled from 'libs/styled';
import transformFlexibleBackground from 'libs/wordpress/utils/transformFlexibleBackground';
import useAboveBreakpoint from 'hooks/useAboveBreakpoint';

const NewsletterBlockWrapper = styled('div', { shouldForwardProp: prop => ['columnStyles'].indexOf(prop) === -1 })``;

const NewsletterModule = ({ content }) => {
    const {
        background_media_flexible: backgroundFlexible,
        settings,
        content: { content_alignment: contentAlignment },
    } = content;

    const activeImage = activeSetting(settings, 'image');
    const contentLeft = contentAlignment === 'left';

    // Tranform Background Data
    const isAboveMd = useAboveBreakpoint('md', activeImage);
    const transformedBackground = transformFlexibleBackground(backgroundFlexible);
    const mobileBackground = transformedBackground.mobile;
    const desktopBackground = transformedBackground.desktop;

    return (
        <MaxWidthWrapper includeContentMargins as="section" my={activeImage ? moduleMargins.xxs : moduleMargins.md}>
            {!activeImage ? (
                <NewsletterBlock content={content} />
            ) : (
                <ColumnsLayout
                    blocks={[
                        <AspectWrapper
                            key="image"
                            mx={[bleedMargins[0], bleedMargins[1], '0']}
                            order={contentLeft ? [1, null, 2] : 1}
                            ratio={3 / 4}
                            size={[12, null, 6]}
                        >
                            <BackgroundSwitch
                                background={isAboveMd ? desktopBackground : mobileBackground}
                                type={isAboveMd ? desktopBackground.type : mobileBackground.type}
                                mediaQuery={mediaQueries.twoColumn}
                            />
                        </AspectWrapper>,
                        <NewsletterBlockWrapper
                            columnStyles={{ mr: 'auto' }}
                            height={[null, null, '100%']}
                            key="newsletter"
                            order={contentLeft ? [2, null, 1] : 2}
                            p={['40px 0', null, '24px 0']}
                            size={[12, null, 6, 5]}
                        >
                            <NewsletterBlock isColumn content={content} />
                        </NewsletterBlockWrapper>,
                    ]}
                />
            )}
        </MaxWidthWrapper>
    );
};

NewsletterModule.propTypes = {
    content: PropTypes.shape({
        content: PropTypes.shape({
            content_alignment: PropTypes.oneOf(['left', 'right']),
        }),
        background_media_flexible: PropTypes.exact({
            mobile: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.object])),
            desktop: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.object])),
        }),
        settings: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
};

export default NewsletterModule;
